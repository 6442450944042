<template>
  <v-row>
    <v-col cols="12" lg="12" class="pb-0">
      <v-radio-group
        v-model="selectSoftSwitchInput"
        @change="getSoftswitchType"
        class="mt-0 py-3"
      >
        <v-radio value="new">
          <template v-slot:label>
            <div class="black--text">New</div>
          </template>
        </v-radio>
        <v-radio value="existing">
          <template v-slot:label>
            <div class="black--text">Existing</div>
          </template>
        </v-radio>
      </v-radio-group>
      <span v-show="showSoftswitchInput">
        <v-form v-model="isFormValid">
          <v-text-field
            v-model="getSelectSoftSwitchData.server_name"
            label="Trunk Name"
            append-icon="person"
            outlined
            rounded
            dense
          ></v-text-field>
          <v-text-field
            v-model="getSelectSoftSwitchData.ip"
            :rules="ipSwitchRules"
            label="Ip"
            append-icon="person"
            outlined
            rounded
            dense
          >
           <template v-slot:append>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="primary">mdi-information</v-icon>
                  </template>
                  <div style="max-width: 250px; white-space: normal; padding: 8px;">
                    By default, calls will be forwarded to SIP port 5060. If your server listens to a different SIP port, enter it here.
                    <br /><br />
                    <strong>Example:</strong> If your server IP address is <strong>192.168.11.1</strong> and it listens to SIP port <strong>5062</strong>,
                    type the address as <strong>192.168.11.1:5062</strong>.
                  </div>
                </v-tooltip>
              </template>
          </v-text-field>
          <v-select
            v-model="getSelectSoftSwitchData.inboundFormat"
            :items="inboundFormatOptions"
            item-text="text"
            item-value="value"
            label="Select Inbound Format"
            append-icon="person"
            outlined
            rounded
            dense
            required
          ></v-select>
           <!-- Conditionally show the text box when "username/Extension@Ip" is selected -->
          <v-text-field
            v-if="getSelectSoftSwitchData.inboundFormat === 'username'"
            v-model="getSelectSoftSwitchData.username"
            label="Enter Username or Extension"
            placeholder="Up to 15 alphanumeric"
            append-icon="person"
            outlined
            rounded
            dense
            :rules="[usernameRule]"
            maxlength="15"
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="px-4"
              dark
              @click="closeAssignNumberModal"
              outlined
            >
              Cancel
            </v-btn>
            <v-btn
              class="px-4 ml-4 primary-btn"
              outlined
              :loading="isLoading"
              @click="addConnectivityServersData"
              :disabled="!isFormValid"
            >
              Assign
            </v-btn>
          </v-card-actions>
        </v-form>
      </span>
      <span v-show="showSoftswitchList">
        <v-select
          :items="sipUriDeviceData"
          v-model="softSwitchData.server_name"
          item-text="server_name"
          item-value="server_name"
          label="Select Trunk"
          @change="getSelectSoftSwitch"
          append-icon="person"
          return-object
          outlined
          rounded
          dense
        >
        </v-select>
        <v-text-field
          v-model="getSelectSoftSwitchData.server_name"
          label="Trunk Name"
          append-icon="person"
          outlined
          rounded
          disabled
          dense
        ></v-text-field>
        <v-text-field
          v-model="getSelectSoftSwitchData.ip"
          :rules="ipSwitchRules"
          label="Ip"
          append-icon="person"
          outlined
          rounded
          disabled
          dense
        >
         <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="primary">mdi-information</v-icon>
              </template>
              <div style="max-width: 250px; white-space: normal; padding: 8px;">
                By default, calls will be forwarded to SIP port 5060. If your server listens to a different SIP port, enter it here.
                <br /><br />
                <strong>Example:</strong> If your server IP address is <strong>192.168.11.1</strong> and it listens to SIP port <strong>5062</strong>,
                type the address as <strong>192.168.11.1:5062</strong>.
              </div>
            </v-tooltip>
          </template>
        </v-text-field>
        <v-select
            v-model="getSelectSoftSwitchData.inboundFormat"
            :items="inboundFormatOptions"
            item-text="text"
            item-value="value"
            label="Select Inbound Format"
            append-icon="person"
            outlined
            rounded
            disabled
            dense
        ></v-select>
         <!-- Conditionally show the text box when "username/Extension@Ip" is selected -->
        <v-text-field
            v-if="getSelectSoftSwitchData.inboundFormat === 'username'"
            v-model="getSelectSoftSwitchData.username"
            label="Enter Username or Extension"
            placeholder="Up to 15 alphanumeric"
            append-icon="person"
            outlined
            rounded
            disabled
            dense
            :rules="[usernameRule]"
            maxlength="15"
        ></v-text-field>
        <v-divider></v-divider>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="px-4"
            outlined
            @click="closeAssignNumberModal"
            dark
          >
            Cancel
          </v-btn>
          <v-btn
            class="px-4 ml-4 primary-btn"
            outlined
            :loading="isLoading"
            @click="assignDidToServersData"
          >
            Assign
          </v-btn>
        </v-card-actions>
      </span>
    </v-col>
  </v-row>
</template>

<script>
import numberManagerAPI from "@/services/numberManagerAPI.js";

export default {
  props: ["sipUriDeviceData", "assigningNumberData"],
  data() {
    return {
      inboundFormatOptions: [
        { text: "DID@IP", value: "strip_plus" },
        { text: "+DID@IP", value: "e164" },
        { text: "username/Extension@Ip", value: "username" }
      ],
      // Validation rule for username/extension field
      usernameRule: v => /^[a-zA-Z0-9]{1,15}$/.test(v) || 'Up to 15 alphanumeric characters only',
      selectSoftSwitchInput: "existing",
      isLoading: false,
      getSelectSoftSwitchData: {
        inboundFormat: '',
        username: '' ,
        isAssignedTrunk: 'Y',
        server_name: '',
        ipAddress: ''
      },
      showSoftswitchList: true,
      showSoftswitchInput: false,
      isFormValid: false,
      ipSwitchRules: [
        // (v) =>
        //   /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
          // Check for forbidden characters (@, /, \)
        (value) => {
          const forbiddenChars = ["@", "/", "\\"]; // Characters to disallow

          if (value && forbiddenChars.some((char) => value.includes(char))) {
            return "Please enter a valid text without @, /, or \\ characters.";
          }
          return true; // Validation passed
        },
        // Ensure value is not empty
        value => !!value || 'IP Address is required', // Required rule
        value => this.isValidIP(value) || 'Invalid IP address' // IP validation rule
      
      ],
      userDetail: {},
      softSwitchData: {},
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
  },
  methods: {
    isValidIP(ip) {
      const ipPattern = /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}(:([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/;
      return ipPattern.test(ip);
    },
    setValue: function () {
      this.getSelectSoftSwitchData = {};
      this.softSwitchData = {};
    },
    closeAssignNumberModal() {
      this.getSelectSoftSwitchData = {};
      this.softSwitchData = {};
      this.$emit("close-asign-modal");
    },
    getSelectSoftSwitch() {
       // Extract username part (before '@') from the `username` value
       let username = '';
       let ipAddress = '';
       console.log("========softSwitchData============="+this.softSwitchData);
       if (this.softSwitchData?.server_name?.auth?.ip) {
        const fullValue = this.softSwitchData.server_name.auth.ip; // Example: "abc@12.13.12.13"
          // Check if fullValue contains '@'
          if (fullValue.includes('@')) {
              // If it contains '@', split into username and ipAddress
              username = fullValue.split('@')[0];
              ipAddress = fullValue.split('@')[1];
          } else {
              // If it doesn't contain '@', set fullValue as ipAddress
              ipAddress = fullValue;
          }
      }
    
      this.getSelectSoftSwitchData = {
        server_name: this.softSwitchData.server_name.server_name,
        ip: ipAddress,
        inboundFormat: this.softSwitchData.server_name.options.inbound_format,
        username: username,
        isAssignedTrunk:"Y",
      };
    },
    getSoftswitchType() {
      if (this.selectSoftSwitchInput == "new") {
        this.showSoftswitchInput = true;
        this.showSoftswitchList = false;
        this.getSelectSoftSwitchData = {};
      }
      if (this.selectSoftSwitchInput == "existing") {
        this.showSoftswitchInput = false;
        this.showSoftswitchList = true;
        this.getSelectSoftSwitchData = {};
        this.softSwitchData = {};
      }
    },
    async assignDidToServersData() {
      if (
        this.getSelectSoftSwitchData.server_name &&
        this.getSelectSoftSwitchData.inboundFormat &&
        this.getSelectSoftSwitchData.ip
      ) {
        this.isLoading = true;
        var didToServerObj = {
          accountId: this.userDetail.accountId,
          authToken: this.userDetail.authToken,
          inventoryItemId: this.assigningNumberData.inventoryItemId,
          ip: this.getSelectSoftSwitchData.ip,
          serverName: this.getSelectSoftSwitchData.server_name,
          inboundFormat: this.getSelectSoftSwitchData.inboundFormat,
          username: this.getSelectSoftSwitchData.username,
          isAssignedTrunk: "Y",
        };
        try {
          let response = await numberManagerAPI.assignDidToServers(
            didToServerObj
          );
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.responseMessage,
            color: "success",
          });
          this.$emit("closedAssignedModal");
          // this.closeAssignNumberModal();
          this.isLoading = false;
        } catch (error) {
          // console.log("====error===", error);
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong!",
            color: "error",
          });
          this.isLoading = false;
        }
      } else {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Fill the fields!",
          color: "error",
        });
      }
    },
    async addConnectivityServersData() {
      if (
        this.getSelectSoftSwitchData.server_name &&
        this.getSelectSoftSwitchData.inboundFormat &&
        this.getSelectSoftSwitchData.ip
      ) {
        this.isLoading = true;
        var didToServerObj = {
          accountId: this.userDetail.accountId,
          authToken: this.userDetail.authToken,
          inventoryItemId: this.assigningNumberData.inventoryItemId,
          ip: this.getSelectSoftSwitchData.ip,
          serverName: this.getSelectSoftSwitchData.server_name,
          inboundFormat: this.getSelectSoftSwitchData.inboundFormat,
          username: this.getSelectSoftSwitchData.username,
          isAssignedTrunk: "Y",
        };
        console.log("didToServerObj===========311======="+this.didToServerObj);
        try {
          let response = await numberManagerAPI.addConnectivityServers(
            didToServerObj
          );
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.responseMessage,
            color: "success",
          });
          this.$emit("closedAssignedModal");
          // this.closeAssignNumberModal();
          this.isLoading = false;
        } catch (error) {
          // console.log("====error===", error);
          this.isLoading = false;
          if(error.data && error.data.messageDetail){
            this.$root.$emit("SHOW_SNACKBAR", {
              text: error.data.messageDetail,
              color: "error",
            });
          }else {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "something wrong!",
              color: "error",
            });
          }
        }
      } else {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Fill the fields!",
          color: "error",
        });
      }
    },
  },
};
</script>